import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { getGuestUser } from "service/users/GuestUsers";

const GuestUsers = () => {
  const theme = useTheme();
  const [guestUserData, setGuestUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchGuestUserList = async () => {
    try {
      setIsLoading(true);
      const response = await getGuestUser();

      setGuestUserData(response.data);
      // console.log("data", updatedUserData);
    } catch (error) {
      console.error("There was a problem fetching the guest user list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGuestUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const column = [
    {
      field: "sno",
      headerName: "S.NO",
      flex: 0.5,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "email_id",
      headerName: "EMAIL",
      flex: 1,
    },
    {
      field: "contact_number",
      headerName: "CONTACT NUMBER",
      flex: 1,
    },
    {
      field: "active_otp",
      headerName: "ACTIVE OTP",
      flex: 1,
    },
    {
      field: "device_id",
      headerName: "DEVICE ID",
      flex: 1,
    },
    {
      field: "os_type",
      headerName: "OS TYPE",
      flex: 1,
    },
    {
      field: "os_version",
      headerName: "OS VERSION",
      flex: 1,
    },
    {
      field: "device_type",
      headerName: "DEVICE TYPE",
      flex: 1,
    },
    {
      field: "device_model",
      headerName: "DEVICE MODEL",
      flex: 1,
    },
    {
      field: "is_active_athlete",
      headerName: "ACTIVE ATHLETE",
      flex: 1,
    },
  ];

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header title="GUEST USERS" subTitle="List of Guest Users" />

        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !guestUserData}
            getRowId={(row) => row.id}
            rows={guestUserData || []}
            columns={column}
            components={{ Toolbar: DataGridCustomToolbar }}
            sx={{
              "& .MuiCircularProgress-circle": {
                color: theme.palette.secondary[300],
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default GuestUsers;
