import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
  FormControl,
  FormLabel,
  FormGroup,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Header from "components/Header";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@emotion/react";
import ShowFullScreenBackdrop from "components/ShowFullScreenBackdrop";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FlexBetween from "components/FlexBetween";
import {
  addAppPost,
  getAppPostById,
  updateAppPost,
} from "service/manage/AppPost";

const AppPostForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    post_category: "",
    post_owner: "",
    tagged_user: "",
    is_qr_sharing: 0,
    is_shareable: 0,
    geo_lat: "",
    geo_long: "",
    geo_radius: "",
    interest_tagged: "",
    for_audience: "",
    appearance_order: "",
    appearance_frequency: "",
    post_comments: "",
    post_reactions: "",
    post_shares: "",
  });
  const [open, setOpen] = useState(false);

  const fetchAppPostByIdData = async () => {
    try {
      setOpen(true);
      if (id) {
        const response = await getAppPostById(id);
        const responseData = response.data;
        const {
          post_category,
          post_owner,
          tagged_user,
          is_qr_sharing,
          is_shareable,
          geo_lat,
          geo_long,
          geo_radius,
          interest_tagged,
          for_audience,
          appearance_order,
          appearance_frequency,
          post_comments,
          post_reactions,
          post_shares,
        } = responseData;

        setFormData((prevData) => ({
          ...prevData,
          post_category,
          post_owner,
          tagged_user,
          is_qr_sharing,
          is_shareable,
          geo_lat,
          geo_long,
          geo_radius,
          interest_tagged,
          for_audience,
          appearance_order,
          appearance_frequency,
          post_comments,
          post_reactions,
          post_shares,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAppPostByIdData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = value;

    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    } else if (
      name === "geo_radius" ||
      name === "geo_long" ||
      name === "geo_lat"
    ) {
      newValue = parseFloat(value); // Convert value to float
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setOpen(true);
      const data = { ...formData };

      const response = id
        ? await updateAppPost(data, id)
        : await addAppPost(data);

      if (response.status === 200 || response.status === 201) {
        setFormData({
          post_category: "",
          post_owner: "",
          tagged_user: "",
          is_qr_sharing: 0,
          is_shareable: 0,
          geo_lat: "",
          geo_long: "",
          geo_radius: "",
          interest_tagged: "",
          for_audience: "",
          appearance_order: "",
          appearance_frequency: "",
          post_comments: "",
          post_reactions: "",
          post_shares: "",
        });
        if (id) {
          await fetchAppPostByIdData();
        }

        const successMessage = id
          ? "App Post updated successfully!"
          : "App Post added successfully!";
        toast.success(successMessage);
      } else {
        console.error(
          "Failed to submit data. Unexpected status code:",
          response.status
        );
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        `Failed to ${id ? "update" : "add"} the app post. Please try again.`;
      toast.error(errorMessage);
    } finally {
      setOpen(false);
    }
  };

  const handleBack = () => {
    navigate("/app-post");
  };

  return (
    <>
      <ToastContainer />
      <Box m="1.5rem 2.5rem">
        <Header
          title={id ? "EDIT APP POST" : "ADD APP POST"}
          subTitle={id ? "Edit App Post" : "Add App Post"}
        />
        <Tooltip title="Back" arrow>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Box
          mt="10px"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <form onSubmit={handleSubmit}>
            <TextField
              id="post_category"
              select
              name="post_category"
              label="Category"
              color="secondary"
              value={formData.post_category}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            >
              <MenuItem value="ads">Ads</MenuItem>
              <MenuItem value="info">Info</MenuItem>
              <MenuItem value="ops_post">Ops Post</MenuItem>
              <MenuItem value="ath_post">Athlete Post</MenuItem>
              <MenuItem value="challenges">Challenges</MenuItem>
            </TextField>

            <TextField
              id="post_owner"
              select
              name="post_owner"
              label="Owner"
              color="secondary"
              value={formData.post_owner}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            >
              <MenuItem value="Official Strikie">Official Strikie</MenuItem>
            </TextField>

            <TextField
              fullWidth
              margin="normal"
              name="tagged_user"
              value={formData.tagged_user}
              onChange={handleChange}
              label="Tagged User"
              color="secondary"
              required
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              fullWidth
              margin="normal"
              name="geo_lat"
              type="number"
              value={formData.geo_lat}
              onChange={handleChange}
              label="Geo Lat"
              color="secondary"
              required
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              fullWidth
              margin="normal"
              name="geo_long"
              type="number"
              value={formData.geo_long}
              onChange={handleChange}
              label="Geo Long"
              color="secondary"
              required
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              fullWidth
              margin="normal"
              name="geo_radius"
              type="number"
              value={formData.geo_radius}
              onChange={handleChange}
              label="Geo Radius"
              color="secondary"
              required
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              id="interest_tagged"
              label="Interest Tagged"
              color="secondary"
              name="interest_tagged"
              value={formData.interest_tagged}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            />

            <TextField
              id="for_audience"
              select
              name="for_audience"
              label="Audience"
              color="secondary"
              value={formData.for_audience}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="guest">Guest</MenuItem>
              <MenuItem value="ath">Athlete</MenuItem>
              <MenuItem value="followers">Followers</MenuItem>
              <MenuItem value="favorites">Favorites</MenuItem>
              <MenuItem value="Private">private</MenuItem>
            </TextField>

            <TextField
              fullWidth
              name="appearance_order"
              type="number"
              value={formData.appearance_order}
              onChange={handleChange}
              margin="normal"
              label="Appearance Order"
              color="secondary"
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              id="appearance_frequency"
              select
              name="appearance_frequency"
              label="Appearance Frequency"
              color="secondary"
              value={formData.appearance_frequency}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            >
              <MenuItem value="0">0</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="30">30</MenuItem>
              <MenuItem value="60">60</MenuItem>
            </TextField>

            <FormControl component="fieldset" sx={{ margin: "0.5rem" }}>
              <FormLabel component="legend" color="secondary">
                Is_qr_sharing
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_qr_sharing === 1}
                      name="is_qr_sharing"
                      color="secondary"
                      onChange={handleChange}
                    />
                  }
                  label={formData.is_qr_sharing === 1 ? "Yes" : "No"}
                />
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" sx={{ margin: "0.5rem" }}>
              <FormLabel component="legend" color="secondary">
                Is shareable
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_shareable === 1}
                      name="is_shareable"
                      color="secondary"
                      onChange={handleChange}
                    />
                  }
                  label={formData.is_shareable === 1 ? "Yes" : "No"}
                />
              </FormGroup>
            </FormControl>

            <TextField
              fullWidth
              multiline
              name="post_comments"
              value={formData.post_comments}
              onChange={handleChange}
              rows={2}
              margin="normal"
              label="Post Comments"
              color="secondary"
              sx={{ margin: "0.5rem", width: "calc(50% - 1rem)" }}
            />

            <TextField
              fullWidth
              multiline
              name="post_reactions"
              value={formData.post_reactions}
              onChange={handleChange}
              rows={2}
              margin="normal"
              label="Post Reactions"
              color="secondary"
              sx={{ margin: "0.5rem", width: "calc(50% - 1rem)" }}
            />

            <TextField
              fullWidth
              multiline
              name="post_shares"
              value={formData.post_shares}
              onChange={handleChange}
              rows={2}
              margin="normal"
              label="Post Shares"
              color="secondary"
              sx={{ margin: "0.5rem", width: "calc(50% - 1rem)" }}
            />

            <FlexBetween>
              <FlexBetween></FlexBetween>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ mt: 2, width: "13rem" }}
              >
                {id ? "Update App Post" : "Add App Post"}
              </Button>
            </FlexBetween>
          </form>
        </Box>
      </Box>
      {open && <ShowFullScreenBackdrop open={open} />}
    </>
  );
};

export default AppPostForm;
