import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  Layout,
  Dashboard,
  LoginForm,
  ChangePassword,
  PageNotFound,
  AppContent,
  AppContentForm,
  AppPost,
  AppPostForm,
  PostContent,
  PostContentForm,
  GuestUsers,
  AthleteUsers,
} from "scenes";
import { PrivateRoutes, PublicRoutes } from "utilities/authRoutes/AuthRoutes";

const RoutesConfig = () => (
  <Routes>
    {/* Private routes accessible after authentication */}
    <Route element={<PrivateRoutes />}>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="guest-users" element={<GuestUsers />} />
        <Route path="athlete-users" element={<AthleteUsers />} />
        <Route path="app-content" element={<AppContent />} />
        <Route path="app-content/add" element={<AppContentForm />} />
        <Route path="app-content/edit/:id" element={<AppContentForm />} />
        <Route path="app-post" element={<AppPost />} />
        <Route path="app-post/add" element={<AppPostForm />} />
        <Route path="app-post/edit/:id" element={<AppPostForm />} />
        <Route path="post-content" element={<PostContent />} />
        <Route path="post-content/add" element={<PostContentForm />} />
        <Route path="post-content/edit/:id" element={<PostContentForm />} />

        {/* Loop through permissions and render authorized routes
        {hasPermission.map((route, index) => {
          // Check if the access level is 'hide', don't render the route
          if (route.access === "hide") return null;

          // Check if the access level is 'write', set isEditable to true
          let isEditable = route.access === "write";

          // Otherwise, render the route
          switch (route.title) {
            case "Mobile App Users":
              return (
                <React.Fragment key={index}>
                  <Route
                    path="app-users"
                    element={<Users isEditable={isEditable} />}
                  />
                  <Route path="users-view/:id" element={<UsersView />} />
                </React.Fragment>
              );
            case "Admin Users":
              return (
                <Route
                  key={index}
                  path="admin-users"
                  element={<AdminUsers isEditable={isEditable} />}
                />
              );

            case "Role":
              return (
                <Route
                  key={index}
                  path="roles"
                  element={<Role isEditable={isEditable} />}
                />
              );
            case "Role Access":
              return (
                <Route
                  key={index}
                  path="role-access"
                  element={<RoleAccess isEditable={isEditable} />}
                />
              );
            default:
              return null; // Handle unknown routes
          }
        })} */}
        <Route path="change-password" element={<ChangePassword />} />
      </Route>
    </Route>

    {/* Public routes accessible without authentication */}
    <Route element={<PublicRoutes />}>
      <Route path="login" element={<LoginForm />} />
    </Route>
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default RoutesConfig;
