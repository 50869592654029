import React, { useState, useEffect } from "react";
import { Box, useTheme, Switch } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import ShowFullScreenBackdrop from "components/ShowFullScreenBackdrop";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteAthleteUser, getAthleteUser } from "service/users/AthleteUsers";
import { downloadResource } from "utilities/firebaseFunctions";

const AthleteUsers = () => {
  const theme = useTheme();
  const [athleteUserData, setAthleteUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchAthleteUserList = async () => {
    try {
      setIsLoading(true);
      const response = await getAthleteUser();

      // Array to hold promises for downloading resources
      const promises = response.data.map(async (item) => {
        const profile_dpPromise = item.profile_dp
          ? downloadResource(item.profile_dp)
          : null;
        const profile_coverPromise = item.profile_cover
          ? downloadResource(item.profile_cover)
          : null;

        // Wait for all promises to settle, regardless of success or failure
        const [profileDpImageUrl, profileCoverImageUrl] = await Promise.all([
          profile_dpPromise,
          profile_coverPromise,
        ]);

        return {
          ...item,
          profile_dp_url: profileDpImageUrl,
          profile_cover_url: profileCoverImageUrl,
        };
      });

      // Wait for all promises to resolve
      const newData = await Promise.all(promises);

      setAthleteUserData(newData);
      // console.log("data", newData);
    } catch (error) {
      console.error(
        "There was a problem fetching the athlete user list:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAthleteUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAthleteUserIsActiveToggle = async (athleteUserId, isActive) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to set this athlete User as ${
          isActive === 1 ? "inactive" : "active"
        }?`
      );

      if (!confirmed) {
        return;
      }
      setOpen(true);

      await deleteAthleteUser(athleteUserId, {
        account_status: isActive === 1 ? 0 : 1,
      });

      // Toggle the isActive value locally without updating the athleteUserData state
      const updatedAthleteUserData = athleteUserData.map((athleteUser) => {
        if (athleteUser.id === athleteUserId) {
          return { ...athleteUser, account_status: isActive === 1 ? 0 : 1 };
        }
        return athleteUser;
      });

      setAthleteUserData(updatedAthleteUserData);

      toast.success(
        `Athlete User is now ${isActive === 1 ? "inactive" : "active"}.`
      );
    } catch (error) {
      toast.error("Failed to update athlete user status.");
    } finally {
      setOpen(false);
    }
  };

  const column = [
    {
      field: "sno",
      headerName: "S.NO",
      flex: 0.5,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "full_name",
      headerName: "FULL NAME",
      flex: 1,
    },
    {
      field: "username",
      headerName: "USER NAME",
      flex: 1,
    },
    {
      field: "email_id",
      headerName: "EMAIL",
      flex: 1,
    },
    {
      field: "phone_no",
      headerName: "PHONE NUMBER",
      flex: 1,
      renderCell: (params) => {
        return params.value?.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      },
    },
    {
      field: "profile_dp_url",
      headerName: "PROFILE",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="dp"
          style={{ width: 35, height: 35, borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "profile_cover_url",
      headerName: "PROFILE COVER",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="cover"
          style={{ width: 50, height: 50, borderRadius: "5px" }}
        />
      ),
    },
    {
      field: "bio_tagline",
      headerName: "TAGLINE",
      flex: 1,
    },
    {
      field: "bio_description",
      headerName: "DESCRIPTION",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "GENDER",
      flex: 1,
    },
    {
      field: "account_status",
      headerName: "STATUS",
      flex: 1,
      renderCell: (params) => {
        const isActive = params.value === 1;
        return (
          <Switch
            color="secondary"
            checked={isActive}
            onClick={() =>
              handleAthleteUserIsActiveToggle(params.row.id, params.value)
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer />
      <Box m="1.5rem 2.5rem">
        <Header title="ATHLETE USERS" subTitle="List of Athlete Users" />

        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !athleteUserData}
            getRowId={(row) => row.id}
            rows={athleteUserData || []}
            columns={column}
            components={{ Toolbar: DataGridCustomToolbar }}
            sx={{
              "& .MuiCircularProgress-circle": {
                color: theme.palette.secondary[300],
              },
            }}
          />
        </Box>
      </Box>
      {open && <ShowFullScreenBackdrop open={open} />}
    </>
  );
};

export default AthleteUsers;
