import React, { useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "redux/state";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import profileImage from "assets/avtar.94b78e6ac5e8b35c30a4.png";
import { useNavigate } from "react-router-dom";
import { useTokenInfo } from "../utilities/authRoutes/AuthRoutes";

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { userRole, userName } = useTokenInfo();

  // State for settings menu
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const isSettingsOpen = Boolean(settingsAnchorEl);
  const handleClickSettings = (event) =>
    setSettingsAnchorEl(event.currentTarget);
  const handleCloseSettings = () => setSettingsAnchorEl(null);

  // State for user profile menu
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const isProfileOpen = Boolean(profileAnchorEl);
  const handleClickProfile = (event) => setProfileAnchorEl(event.currentTarget);
  const handleCloseProfile = () => setProfileAnchorEl(null);

  const handleSettings = () => {
    handleCloseSettings();
    navigate("/change-password");
  };

  const handleProfile = () => {
    handleCloseProfile();
    localStorage.removeItem("roleId");
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    navigate("/login");
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* Dark/Light mode */}
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>

          {/* Settings icon with menu */}
          <IconButton onClick={handleClickSettings}>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton>
          <Menu
            anchorEl={settingsAnchorEl}
            open={isSettingsOpen}
            onClose={handleCloseSettings}
          >
            {/* Your settings options */}
            <MenuItem onClick={handleSettings}>Change Password</MenuItem>
          </Menu>

          {/* User profile menu */}
          <FlexBetween>
            {/* Profile button with dropdown */}
            <Button
              onClick={handleClickProfile}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {userName ? userName.split(" ")[0].toUpperCase() : ""}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {userRole === 1
                    ? "SUPER ADMIN"
                    : userRole === 2
                    ? "ADMIN"
                    : userRole === 3
                    ? "MANAGER"
                    : userRole === 4
                    ? "SATFF"
                    : null}{" "}
                </Typography>
              </Box>

              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={profileAnchorEl}
              open={isProfileOpen}
              onClose={handleCloseProfile}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              {/* Your profile options */}
              <MenuItem onClick={handleProfile}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
