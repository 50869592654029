import React, { useMemo } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";

export const useTokenInfo = () => {
  const token = localStorage.getItem("token");

  const userInfo = useMemo(() => {
    if (!token) {
      return { userRole: null, userName: null, isTokenValid: false };
    }

    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    const isValid = currentTime < expirationTime;

    // Clear local storage if token is expired
    if (!isValid) {
      localStorage.removeItem("roleId");
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
    }

    return {
      userRole: decodedToken.roleId,
      userName: decodedToken.first_name,
      isTokenValid: isValid,
    };
  }, [token]);

  return userInfo;
};

export const PrivateRoutes = () => {
  const { isTokenValid } = useTokenInfo();
  return isTokenValid ? <Outlet /> : <Navigate to="/login" />;
};

export const PublicRoutes = () => {
  const { isTokenValid } = useTokenInfo();
  return isTokenValid ? <Navigate to="/" /> : <Outlet />;
};

const decryptPermissions = (encryptedPermissions) => {
  if (!encryptedPermissions) return [];
  try {
    // Decrypt permissions string
    const bytes = CryptoJS.AES.decrypt(encryptedPermissions, "secret_key");
    // Convert decrypted data to JSON
    const decryptedPermissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedPermissions;
  } catch (error) {
    console.error("Error decrypting permissions:", error);
    return [];
  }
};

export const usePermissionsData = () => {
  const permissionData = useMemo(() => {
    const encryptedPermissions = localStorage.getItem("permissions");
    // Decrypt permissions data, or return an empty array if no data exists
    return decryptPermissions(encryptedPermissions);
  }, []);
  return permissionData;
};
