import React, { useEffect, useState } from "react";
import { Box, useTheme, Button, Tooltip, Switch } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "../../components/FlexBetween";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ShowFullScreenBackdrop from "components/ShowFullScreenBackdrop";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteAppPost, getAppPost } from "service/manage/AppPost";

const AppPost = () => {
  const theme = useTheme();
  const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchAppPostList = async () => {
    try {
      setIsLoading(true);
      const response = await getAppPost();
      setPostData(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("There was a problem fetching the app post list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAppPostList();
  }, []);

  const handleAppPostIsActiveToggle = async (postId, isActive) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to set this app post as ${
          isActive === 1 ? "inactive" : "active"
        }?`
      );

      if (!confirmed) {
        return;
      }
      setOpen(true);

      await deleteAppPost(postId, {
        post_status: isActive === 1 ? 0 : 1,
      });

      // Toggle the isActive value locally without updating the postData state
      const updatedPostData = postData.map((post) => {
        if (post.id === postId) {
          return { ...post, post_status: isActive === 1 ? 0 : 1 };
        }
        return post;
      });

      setPostData(updatedPostData);
      toast.success(
        `App Post is now ${isActive === 1 ? "inactive" : "active"}.`
      );
    } catch (error) {
      toast.error("Failed to update app post status.");
    } finally {
      setOpen(false);
    }
  };

  const column = [
    {
      field: "#",
      headerName: "S.NO",
      flex: 0.5,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "post_category",
      headerName: "CATEGORY",
      flex: 1,
    },
    {
      field: "post_owner",
      headerName: "OWNER",
      flex: 1,
    },
    {
      field: "tagged_user",
      headerName: "USER",
      flex: 1,
    },
    {
      field: "interest_tagged",
      headerName: "INTEREST",
      flex: 1,
    },
    {
      field: "for_audience",
      headerName: "AUDIENCE",
      flex: 1,
    },
    {
      field: "post_status",
      headerName: "STATUS",
      flex: 0.6,
      renderCell: (params) => {
        const isActive = params.value === 1;
        return (
          <Switch
            color="secondary"
            checked={isActive}
            onClick={() =>
              handleAppPostIsActiveToggle(params.row.id, params.value)
            }
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 0.8,
      renderCell: (params) => {
        const contentId = params.row.id;
        return (
          <>
            <Box sx={{ mr: 2, cursor: "pointer" }}>
              <Link to={`/app-post/edit/${contentId}`}>
                <Tooltip title="Edit" placement="top" arrow>
                  <Edit />
                </Tooltip>
              </Link>{" "}
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer />
      <Box m="1.5rem 2.5rem">
        <Header title="APP POST" subTitle="List of App Post" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {" "}
          <FlexBetween>
            <FlexBetween></FlexBetween>
            <Button
              component={Link}
              to="/app-post/add"
              sx={{
                mb: "0.5rem",
                width: "8rem",
              }}
              startIcon={<AddCircleIcon />}
              variant="contained"
              color="secondary"
            >
              Add New
            </Button>
          </FlexBetween>
          <DataGrid
            loading={isLoading || !postData}
            getRowId={(row) => row.id}
            rows={postData || []}
            columns={column}
            components={{ Toolbar: DataGridCustomToolbar }}
            sx={{
              "& .MuiCircularProgress-circle": {
                color: theme.palette.secondary[300],
              },
            }}
          />
        </Box>
      </Box>
      {open && <ShowFullScreenBackdrop open={open} />}
    </>
  );
};

export default AppPost;
