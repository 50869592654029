import React, { useMemo } from "react";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { themeSettings } from "theme";
import RoutesConfig from "routes/Routes";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RoutesConfig />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
