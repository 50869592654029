import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./state/index";
import paginationReducer from "./paginationSlice";

const store = configureStore({
  reducer: {
    global: globalReducer,
    pagination: paginationReducer,
  },
});

export default store;
