import { Dialog } from "@mui/material";
import AppContentView from "./AppContentView";

const AppContentModal = ({ open, handleClose, appContentData }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <AppContentView
        handleClose={handleClose}
        appContentData={appContentData}
      />
    </Dialog>
  );
};

export default AppContentModal;
