import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React from "react";

const ShowFullScreenBackdrop = ({ open }) => {
  return (
    <div>
      <Backdrop
        open={open}
        sx={{
          zIndex: 99999,
          color: "#ffffff",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography
            variant="h4"
            component="h4"
            sx={{
              marginTop: "20px",
            }}
          >
            Please wait, we are processing your request.
          </Typography>
        </div>
      </Backdrop>
    </div>
  );
};

export default ShowFullScreenBackdrop;
