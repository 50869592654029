import axiosHandler from "interceptor/axiosInterceptor";

export const getAthleteUser = async () => {
  try {
    const response = await axiosHandler.get("/api/athelete-user/auth/details");
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAthleteUser = async (athleteUserId, isActive) => {
  try {
    const response = await axiosHandler.put(
      `/api/athelete-user/auth/delete/${athleteUserId}`,
      isActive
    );
    return response;
  } catch (error) {
    throw error;
  }
};
