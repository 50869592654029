// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCDvwpfaXP4R22yHAtmX5EacECWw_spdtI",
  authDomain: "np-athletify.firebaseapp.com",
  projectId: "np-athletify",
  storageBucket: "poc-video-stream",
  messagingSenderId: "507781578198",
  appId: "1:507781578198:web:55770c1e22b358acedb877",
  measurementId: "G-QV9FYKTDF5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase Storage
const storage = getStorage(app);

// Function to handle file upload to Firebase Storage
export const handleFileUpload = async (file, fileType, filePath) => {
  try {
    // Construct reference to the storage location
    let fileName;
    const timestamp = Date.now(); // Current timestamp in milliseconds

    if (fileType === "background_image") {
      fileName = `bg-image-${timestamp}`;
    } else if (fileType === "foreground_image") {
      fileName = `fg-image-${timestamp}`;
    } else if (fileType === "foreground_video") {
      fileName = `fg-video-${timestamp}`;
    } else {
      fileName = `${file.name}-${timestamp}`;
    }

    // Construct reference to the storage location
    const storageRef = ref(storage, `${filePath}/${fileName}`);

    // Upload the file
    await uploadBytes(storageRef, file);

    // Get the path_ property directly and return it
    const path_ = storageRef._location.path_;

    return path_;
  } catch (error) {
    // Handle errors
    console.error("Error uploading file:", error);
    throw error;
  }
};

// Function to get download URL
export const downloadResource = async (storagePath) => {
  try {
    // Get the download URL for the file at the given path
    const downloadURL = await getDownloadURL(ref(storage, storagePath));
    return downloadURL;
  } catch (error) {
    // Handle errors
    console.error("Error getting download URL for path:", storagePath, error);
    if (error.code === "storage/object-not-found") {
      // File not found, return null
      console.error("File not found at path:", storagePath);
      return null;
    } else {
      // Unexpected error, throw an exception
      throw new Error("Unexpected error occurred while getting download URL.");
    }
  }
};
