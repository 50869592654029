import React from "react";
import { Typography, Container, Grid, IconButton } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginIcon from "@mui/icons-material/Login";

import { Link } from "react-router-dom";

const PageNotFound = () => {
  const handleLogout = () => {
    localStorage.removeItem("roleId");
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    window.location.href = "/login";
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <Grid item>
          <Typography variant="h1" color="secondary">
            404
          </Typography>
        </Grid>
        <Grid item>
          <SentimentVeryDissatisfiedIcon
            color="secondary"
            style={{ fontSize: 80 }}
          />{" "}
        </Grid>
        <Grid item>
          <Typography variant="h4" color="textSecondary">
            Page Not Found
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Oops! The page you are looking for does not exist.
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            component={Link}
            to="/login"
            aria-label="Back to Login"
            color="secondary"
          >
            <ArrowBackIcon style={{ fontSize: 30 }} />
          </IconButton>
          <IconButton
            onClick={handleLogout} // Call handleLogout function when clicked
            aria-label="Back to Login"
            color="secondary"
          >
            <LoginIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageNotFound;
