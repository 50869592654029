import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
  FormControl,
  FormLabel,
  FormGroup,
  Switch,
  Divider,
  FormControlLabel,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import Header from "components/Header";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@emotion/react";
import ShowFullScreenBackdrop from "components/ShowFullScreenBackdrop";
import { MuiColorInput } from "mui-color-input";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  addAppContent,
  getAppContentById,
  updateAppContent,
} from "service/manage/AppContent";
import FlexBetween from "components/FlexBetween";
import { formats, modules } from "utilities/CommonUtility";
import dayjs from "dayjs";
import { handleFileUpload } from "utilities/firebaseFunctions";

const AppContentForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    content_category: "",
    content_type: "",
    title_heading: "",
    subheading: "",
    trigger_type: "",
    background_color: "",
    background_opacity: 0,
    content_expiry: null,
    description: "",
    cta_buttons: "",
    is_shareable: 0,
    html_content: "",
    background_image: null,
    foreground_video: null,
    foreground_image: null,
  });
  const [fileLoading, setFileLoading] = useState({
    background_image: false,
    foreground_image: false,
    foreground_video: false,
  });
  const [open, setOpen] = useState(false);

  const fetchAppContentByIdData = async () => {
    try {
      setOpen(true);
      if (id) {
        const response = await getAppContentById(id);
        const responseData = response.data;
        const {
          content_category,
          content_type,
          title_heading,
          subheading,
          trigger_type,
          background_color,
          background_opacity,
          content_expiry,
          description,
          cta_buttons,
          is_shareable,
          html_content,
          background_image,
          foreground_video,
          foreground_image,
        } = responseData;

        // Removing '%' symbol from background_opacity
        const new_background_opacity = background_opacity.slice(0, -1);

        setFormData((prevData) => ({
          ...prevData,
          content_category,
          content_type,
          title_heading,
          subheading,
          trigger_type,
          background_color,
          background_opacity: new_background_opacity,
          content_expiry: dayjs(content_expiry), // Assuming you are using dayjs to parse the date
          description,
          cta_buttons,
          is_shareable,
          html_content,
          background_image,
          foreground_video,
          foreground_image,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAppContentByIdData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleQuillChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      html_content: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      content_expiry: date,
    }));
  };

  const handleColorChange = (color) => {
    setFormData((prevData) => ({
      ...prevData,
      background_color: color,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setOpen(true);
      const data = { ...formData };

      // Append '%' symbol to the background opacity value
      data.background_opacity = data.background_opacity + "%";

      const response = id
        ? await updateAppContent(data, id)
        : await addAppContent(data);

      if (response.status === 200 || response.status === 201) {
        setFormData({
          content_category: "",
          content_type: "",
          title_heading: "",
          subheading: "",
          trigger: "",
          background_color: "",
          background_opacity: 0,
          content_expiry: null,
          description: "",
          cta_buttons: "",
          is_shareable: false,
          html_content: "",
          background_image: null,
          foreground_video: null,
          foreground_image: null,
        });
        if (id) {
          await fetchAppContentByIdData();
        }

        const successMessage = id
          ? "App Content updated successfully!"
          : "App Content added successfully!";
        toast.success(successMessage);
      } else {
        console.error(
          "Failed to submit data. Unexpected status code:",
          response.status
        );
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        `Failed to ${id ? "update" : "add"} the app content. Please try again.`;
      toast.error(errorMessage);
    } finally {
      setOpen(false);
    }
  };

  const handleFileChange = async (e, fileType, filePath) => {
    try {
      const file = e.target.files[0];

      // Ensure a file is selected
      if (!file) {
        throw new Error("No file selected.");
      }

      // Set loading state for the corresponding file type to true
      setFileLoading((prevLoading) => ({
        ...prevLoading,
        [fileType]: true,
      }));

      // Upload the file and get the download URL
      const url = await handleFileUpload(file, fileType, filePath);

      // Update form data with the download URL
      setFormData((prevData) => ({
        ...prevData,
        [fileType]: url,
      }));
    } catch (error) {
      console.error("Error handling file change:", error);
    } finally {
      setFileLoading((prevLoading) => ({
        ...prevLoading,
        [fileType]: false,
      }));
    }
  };

  const handleBack = () => {
    navigate("/app-content");
  };

  return (
    <>
      <ToastContainer />
      <Box m="1.5rem 2.5rem">
        <Header
          title={id ? "EDIT APP CONTENT" : "ADD APP CONTENT"}
          subTitle={id ? "Edit App Content" : "Add App Content"}
        />
        <Tooltip title="Back" arrow>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Box
          mt="10px"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <form onSubmit={handleSubmit}>
            <TextField
              id="content_category"
              select
              name="content_category"
              label="Category"
              color="secondary"
              value={formData.content_category}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            >
              <MenuItem value="into-slides">Into-Slides</MenuItem>
              <MenuItem value="privacy-policy">Privacy-Policy</MenuItem>
              <MenuItem value="faq">faq</MenuItem>
              <MenuItem value="terms-condition">Terms-Condition</MenuItem>
            </TextField>

            <TextField
              id="content_type"
              select
              label="Type"
              name="content_type"
              color="secondary"
              value={formData.content_type}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            >
              <MenuItem value="Image">Image</MenuItem>
              <MenuItem value="Video">Video</MenuItem>
              <MenuItem value="HTML">HTML</MenuItem>
            </TextField>

            <TextField
              fullWidth
              margin="normal"
              name="title_heading"
              value={formData.title_heading}
              onChange={handleChange}
              label="Heading"
              color="secondary"
              required
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              fullWidth
              margin="normal"
              name="subheading"
              value={formData.subheading}
              onChange={handleChange}
              label="Sub Heading"
              color="secondary"
              required
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              id="trigger_type"
              select
              label="Trigger"
              color="secondary"
              name="trigger_type"
              value={formData.trigger_type}
              onChange={handleChange}
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              required
            >
              <MenuItem value="first_open">First_Open</MenuItem>
              <MenuItem value="reopen">Reopen</MenuItem>
            </TextField>

            <MuiColorInput
              color="secondary"
              format="hex"
              name="background_color"
              value={formData.background_color}
              onChange={handleColorChange}
              placeholder="Select color"
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <TextField
              fullWidth
              margin="normal"
              name="background_opacity"
              value={formData.background_opacity}
              onChange={handleChange}
              label="Background Opacity"
              color="secondary"
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 100,
                },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              required
              sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                fullWidth
                margin="normal"
                color="secondary"
                label="Content Expiry"
                slotProps={{ textField: { color: "secondary" } }}
                value={formData.content_expiry}
                required
                onChange={handleDateChange}
                sx={{ margin: "0.5rem", width: "calc(25% - 1rem)" }}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              multiline
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows={2}
              margin="normal"
              label="Description"
              color="secondary"
              sx={{ margin: "0.5rem", width: "calc(50% - 1rem)" }}
            />

            <TextField
              fullWidth
              multiline
              name="cta_buttons"
              value={formData.cta_buttons}
              onChange={handleChange}
              rows={2}
              margin="normal"
              label="Cta_Buttons"
              color="secondary"
              sx={{ margin: "0.5rem", width: "calc(50% - 1rem)" }}
            />

            <Button
              component="label"
              variant="outlined"
              color="secondary"
              sx={{ margin: "0.5rem", mt: "1rem", width: "calc(25% - 1rem)" }}
            >
              {fileLoading.background_image ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Background Image"
              )}{" "}
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFileChange(e, "background_image", "app-content")
                }
                style={{ display: "none" }}
              />
            </Button>
            <TextField
              fullWidth
              margin="normal"
              label="Background Image URL"
              value={formData.background_image || ""}
              disabled
              variant="standard"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              sx={{ margin: "0.5rem", width: "calc(75% - 1rem)" }}
            />

            <Button
              component="label"
              variant="outlined"
              color="secondary"
              sx={{ margin: "0.5rem", mt: "1rem", width: "calc(25% - 1rem)" }}
            >
              {fileLoading.foreground_image ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Foreground Image"
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFileChange(e, "foreground_image", "app-content")
                }
                style={{ display: "none" }}
              />
            </Button>
            <TextField
              fullWidth
              margin="normal"
              label="Foreground Image URL"
              value={formData.foreground_image || ""}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              size="small"
              color="secondary"
              sx={{ margin: "0.5rem", width: "calc(75% - 1rem)" }}
            />

            <Button
              component="label"
              variant="outlined"
              color="secondary"
              sx={{ margin: "0.5rem", mt: "1rem", width: "calc(25% - 1rem)" }}
            >
              {fileLoading.foreground_video ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Foreground Video"
              )}
              <input
                type="file"
                accept="video/*"
                onChange={(e) =>
                  handleFileChange(e, "foreground_video", "app-content")
                }
                style={{ display: "none" }}
              />
            </Button>
            <TextField
              fullWidth
              margin="normal"
              label="Foreground Video URL"
              value={formData.foreground_video || ""}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              color="secondary"
              size="small"
              sx={{ margin: "0.5rem", width: "calc(75% - 1rem)" }}
            />

            <FormControl component="fieldset" sx={{ margin: "0.5rem" }}>
              <FormLabel component="legend" color="secondary">
                Is shareable
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_shareable === 1}
                      name="is_shareable"
                      color="secondary"
                      onChange={handleChange}
                    />
                  }
                  label={formData.is_shareable === 1 ? "Yes" : "No"}
                />
              </FormGroup>
            </FormControl>

            <Divider
              sx={{ mt: 2, mb: 2 }}
              orientation="horizontal"
              variant="fullWidth"
            >
              HTML SECTION
            </Divider>

            <ReactQuill
              style={{ margin: "0.5rem" }}
              theme="snow"
              value={formData.html_content}
              onChange={handleQuillChange}
              modules={modules}
              formats={formats}
            />

            <FlexBetween>
              <FlexBetween></FlexBetween>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={
                  fileLoading.background_image ||
                  fileLoading.foreground_image ||
                  fileLoading.foreground_video
                } // Disable the button if any of the files are loading
                sx={{ mt: 2, width: "13rem" }}
              >
                {id ? "Update App Content" : "Add App Content"}
              </Button>
            </FlexBetween>
          </form>
        </Box>
      </Box>
      {open && <ShowFullScreenBackdrop open={open} />}
    </>
  );
};

export default AppContentForm;
