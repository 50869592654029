import React from "react";
import {
  Card,
  CardHeader,
  useTheme,
  IconButton,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PostContentView = ({ handleClose, postContentData }) => {
  const theme = useTheme();
  const hasBackgroundColor = postContentData?.background_color;
  const hasBgImage = postContentData?.background_image_url;
  const hasVideo = postContentData?.foreground_video_url;
  const hasFgImage = postContentData?.foreground_image_url;

  return (
    <Card
      sx={{
        minWidth: 350,
        maxWidth: 350,
        height: 550,
        overflow: "auto",
        backgroundColor: hasBackgroundColor
          ? postContentData.background_color
          : theme.palette.background.alt,
        backgroundImage: hasBgImage
          ? `url(${postContentData.background_image_url})`
          : "none",
        backgroundSize: "cover",
        color: hasBackgroundColor || hasBgImage ? "white" : "inherit",
        textShadow:
          hasBackgroundColor || hasBgImage
            ? "1px 1px 2px rgba(0, 0, 0, 0.5)"
            : "none",
        position: "relative",
      }}
    >
      <CardHeader
        action={
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color:
                hasBackgroundColor || hasBgImage
                  ? theme.palette.primary[900]
                  : theme.palette.primary[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      {hasFgImage && !hasVideo && (
        <CardMedia
          component="img"
          src={postContentData.foreground_image_url}
          alt="foreground_image"
          style={{ height: "75vh", objectFit: "inherit" }}
        />
      )}
      {hasVideo && (
        <CardMedia
          component="video"
          controls
          autoPlay
          src={postContentData.foreground_video_url}
          alt="foreground_video"
          style={{ height: "75vh", objectFit: "inherit" }}
        />
      )}
      <CardContent
        sx={{
          position: "absolute",
          bottom: "10px",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)",
        }}
      >
        <Typography variant="body2" sx={{ fontSize: "14px" }}>
          {postContentData?.title_heading}
        </Typography>
        <Typography variant="body2">{postContentData?.subheading}</Typography>
      </CardContent>
    </Card>
  );
};

export default PostContentView;
