import axiosHandler from "interceptor/axiosInterceptor";

export const signIn = async (data) => {
  try {
    const response = await axiosHandler.post("/admin/auth/sign-in", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendEmail = async (data) => {
  try {
    const response = await axiosHandler.post("/admin/auth/forgot-password", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyOTP = async (data) => {
  try {
    const response = await axiosHandler.post("/admin/auth/otp-verify", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (data) => {
  try {
    const response = await axiosHandler.post("/admin/auth/reset-password", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const passwordChange = async (data) => {
  try {
    const response = await axiosHandler.post("/admin/auth/change-password", data);
    return response;
  } catch (error) {
    throw error;
  }
};
