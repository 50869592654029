import axiosHandler from "interceptor/axiosInterceptor";

export const getPostContent = async () => {
  try {
    const response = await axiosHandler.get("app-post-content/details");
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPostContentById = async (postId) => {
  try {
    const response = await axiosHandler.get(`/app-post-content/post-content/${postId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addPostContent = async (data) => {
  try {
    const response = await axiosHandler.post("/app-post-content/add", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePostContent = async (data, postId) => {
  try {
    const response = await axiosHandler.put(
      `/app-post-content/edit-post-content/${postId}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletePostContent = async (postId, post_status) => {
  try {
    const response = await axiosHandler.put(
      `/app-post-content/delete/${postId}`,
      post_status
    );
    return response;
  } catch (error) {
    throw error;
  }
};
