import React, { useEffect, useState } from "react";
import { Box, useTheme, Button, Tooltip, Switch } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "../../components/FlexBetween";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ShowFullScreenBackdrop from "components/ShowFullScreenBackdrop";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteAppContent, getAppContent } from "service/manage/AppContent";
import { downloadResource } from "utilities/firebaseFunctions";
import AppContentModal from "components/app-content/AppContentModal";
import PreviewIcon from "@mui/icons-material/Preview";

const AppContent = () => {
  const theme = useTheme();
  const [contentData, setContentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [appContentViewData, setAppContentViewData] = useState({});

  const handleDialogOpen = (data) => {
    setDialogOpen(true);
    setAppContentViewData(data);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const fetchAppContentList = async () => {
    try {
      setIsLoading(true);
      const response = await getAppContent();

      // Array to hold promises for downloading resources
      const promises = response.data.map(async (item) => {
        const backgroundPromise = item.background_image
          ? downloadResource(item.background_image)
          : null;
        const foregroundPromise = item.foreground_image
          ? downloadResource(item.foreground_image)
          : null;
        const videoPromise = item.foreground_video
          ? downloadResource(item.foreground_video)
          : null;

        // Wait for all promises to settle, regardless of success or failure
        const [backgroundImageUrl, foregroundImageUrl, foregroundVideoUrl] =
          await Promise.all([
            backgroundPromise,
            foregroundPromise,
            videoPromise,
          ]);

        return {
          ...item,
          background_image_url: backgroundImageUrl,
          foreground_image_url: foregroundImageUrl,
          foreground_video_url: foregroundVideoUrl,
        };
      });

      // Wait for all promises to resolve
      const newData = await Promise.all(promises);
      setContentData(newData);
    } catch (error) {
      console.error(
        "There was a problem fetching the app content list:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAppContentList();
  }, []);

  const handleAppContentIsActiveToggle = async (contentId, isActive) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to set this app content as ${
          isActive === 1 ? "inactive" : "active"
        }?`
      );

      if (!confirmed) {
        return;
      }
      setOpen(true);

      await deleteAppContent(contentId, {
        content_status: isActive === 1 ? 0 : 1,
      });

      // Toggle the isActive value locally without updating the contentData state
      const updatedContentData = contentData.map((content) => {
        if (content.id === contentId) {
          return { ...content, content_status: isActive === 1 ? 0 : 1 };
        }
        return content;
      });

      setContentData(updatedContentData);
      toast.success(
        `App Content is now ${isActive === 1 ? "inactive" : "active"}.`
      );
    } catch (error) {
      toast.error("Failed to update app content status.");
    } finally {
      setOpen(false);
    }
  };

  const column = [
    {
      field: "#",
      headerName: "S.NO",
      flex: 0.5,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "content_category",
      headerName: "CATEGORY",
      flex: 1,
    },
    {
      field: "content_type",
      headerName: "TYPE",
      flex: 1,
    },
    {
      field: "title_heading",
      headerName: "HEADING",
      flex: 1,
    },
    {
      field: "subheading",
      headerName: "SUB HEADING",
      flex: 1,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
    },
    {
      field: "content_status",
      headerName: "STATUS",
      flex: 0.6,
      renderCell: (params) => {
        const isActive = params.value === 1;
        return (
          <Switch
            color="secondary"
            checked={isActive}
            onClick={() =>
              handleAppContentIsActiveToggle(params.row.id, params.value)
            }
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 0.8,
      renderCell: (params) => {
        const contentId = params.row.id;
        return (
          <>
            <Box sx={{ mr: 2, cursor: "pointer" }}>
              <Link to={`/app-content/edit/${contentId}`}>
                <Tooltip title="Edit" placement="top" arrow>
                  <Edit />
                </Tooltip>
              </Link>{" "}
            </Box>
            <Box sx={{ mr: 2, cursor: "pointer" }}>
              <Tooltip title="View" placement="top" arrow>
                <PreviewIcon onClick={() => handleDialogOpen(params.row)} />
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer />
      <Box m="1.5rem 2.5rem">
        <Header title="APP CONTENT" subTitle="List of App Content" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <AppContentModal
            open={dialogOpen}
            handleClose={handleDialogClose}
            appContentData={appContentViewData}
          />{" "}
          <FlexBetween>
            <FlexBetween></FlexBetween>
            <Button
              component={Link}
              to="/app-content/add"
              sx={{
                mb: "0.5rem",
                width: "8rem",
              }}
              startIcon={<AddCircleIcon />}
              variant="contained"
              color="secondary"
            >
              Add New
            </Button>
          </FlexBetween>
          <DataGrid
            loading={isLoading || !contentData}
            getRowId={(row) => row.id}
            rows={contentData || []}
            columns={column}
            components={{ Toolbar: DataGridCustomToolbar }}
            sx={{
              "& .MuiCircularProgress-circle": {
                color: theme.palette.secondary[300],
              },
            }}
          />
        </Box>
      </Box>
      {open && <ShowFullScreenBackdrop open={open} />}
    </>
  );
};

export default AppContent;
