import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  Groups2Outlined,
  FiberManualRecord,
  // SettingsOutlined,
  // BusinessOutlined,
  DensitySmallOutlined,
  // AdminPanelSettingsOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Management",
    icon: null,
  },
  {
    text: "Users",
    icon: <Groups2Outlined />,
    children: [
      // {
      //   text: "App Users",
      //   icon: <FiberManualRecord fontSize="1rem" />,
      // },
      // {
      //   text: "Admin Users",
      //   icon: <FiberManualRecord fontSize="1rem" />,
      // },
      {
        text: "Guest Users",
        icon: <FiberManualRecord fontSize="1rem" />,
      },
      {
        text: "Athlete Users",
        icon: <FiberManualRecord fontSize="1rem" />,
      },
    ],
  },
  {
    text: "Manage",
    icon: <DensitySmallOutlined />,
    children: [
      {
        text: "App Content",
        icon: <FiberManualRecord fontSize="1rem" />,
      },
      {
        text: "App Post",
        icon: <FiberManualRecord fontSize="1rem" />,
      },
      {
        text: "Post Content",
        icon: <FiberManualRecord fontSize="1rem" />,
      },
    ],
  },
  // {
  //   text: "Permission",
  //   icon: null,
  // },
  // {
  //   text: "Roles",
  //   icon: <BusinessOutlined />,
  // },
  // {
  //   text: "Role Access",
  //   icon: <AdminPanelSettingsOutlined />,
  // },
  // {
  //   text: "Setting & Content",
  //   icon: null,
  // },
  // {
  //   text: "Settings",
  //   icon: <SettingsOutlined />,
  //   children: [],
  // },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
  hasPermission,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [openChildren, setOpenChildren] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const allChildrenHidden = (children) => {
    return children.every((child) => {
      const childPermission = hasPermission?.find(
        (item) => item.title === child.text
      );
      const childAccess = childPermission && childPermission.access === "hide";
      return childAccess;
    });
  };

  const handleToggleChildren = (text) => {
    setOpenChildren((prevOpenChildren) =>
      prevOpenChildren.includes(text)
        ? prevOpenChildren.filter((item) => item !== text)
        : [...prevOpenChildren, text]
    );
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    STRIKIE ADMIN
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon, children }) => {
                const hasChildren = children && children.length > 0;
                const isChildrenOpen = openChildren.includes(text);

                const permission =
                  hasPermission &&
                  hasPermission?.find((item) => item.title === text);
                const access = permission && permission.access === "hide";

                if (access || (hasChildren && allChildrenHidden(children))) {
                  return null; // Hide the menu item if access is set to "hide" or if all children are hidden
                }

                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }

                const modifiedText = text.includes(" ")
                  ? text.replace(/\s+/g, "-")
                  : text;
                const lcText = modifiedText.toLowerCase();

                return (
                  <React.Fragment key={text}>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (hasChildren) {
                            handleToggleChildren(text);
                          } else {
                            navigate(`/${lcText}`);
                            setActive(lcText);
                          }
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary[300]
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {hasChildren && (
                          <ChevronRightOutlined
                            sx={{
                              ml: "auto",
                              transform: isChildrenOpen
                                ? "rotate(90deg)"
                                : "rotate(0deg)",
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                    {hasChildren && isChildrenOpen && (
                      <List>
                        {children.map(
                          ({ text: childText, icon: childIcon }) => {
                            const modifiedText = childText.includes(" ")
                              ? childText.replace(/\s+/g, "-")
                              : childText;
                            const lcChildText = modifiedText.toLowerCase();

                            const childPermission =
                              hasPermission &&
                              hasPermission?.find(
                                (item) => item.title === childText
                              );

                            const childAccess =
                              childPermission &&
                              childPermission.access === "hide";

                            if (childAccess) {
                              return null; // Hide the child menu item if access is set to "hide"
                            }

                            return (
                              <ListItem
                                key={childText}
                                disablePadding
                                onClick={() => {
                                  navigate(`/${lcChildText}`);
                                  setActive(lcChildText);
                                }}
                              >
                                <ListItemButton
                                  sx={{
                                    backgroundColor:
                                      active === lcChildText
                                        ? theme.palette.secondary[300]
                                        : "transparent",
                                    color:
                                      active === lcChildText
                                        ? theme.palette.primary[600]
                                        : theme.palette.secondary[100],
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      ml: "2rem",
                                      color:
                                        active === lcChildText
                                          ? theme.palette.primary[600]
                                          : theme.palette.secondary[200],
                                    }}
                                  >
                                    {childIcon}
                                  </ListItemIcon>
                                  <ListItemText primary={childText} />
                                </ListItemButton>
                              </ListItem>
                            );
                          }
                        )}
                      </List>
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
