import React from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { Box } from "@mui/material";

const Dashboard = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      </FlexBetween>
    </Box>
  );
};

export default Dashboard;
