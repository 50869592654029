import axiosHandler from "interceptor/axiosInterceptor";

export const getAppContent = async () => {
  try {
    const response = await axiosHandler.get("/app-content/details");
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAppContentById = async (appContentId) => {
  try {
    const response = await axiosHandler.get(
      `/app-content/content/${appContentId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const addAppContent = async (data) => {
  try {
    const response = await axiosHandler.post("/app-content/add", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAppContent = async (data, appContentId) => {
  try {
    const response = await axiosHandler.put(
      `/app-content/edit-content/${appContentId}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAppContent = async (appContentId, content_status) => {
  try {
    const response = await axiosHandler.put(
      `/app-content/delete/${appContentId}`,
      content_status
    );
    return response;
  } catch (error) {
    throw error;
  }
};
