import axiosHandler from "interceptor/axiosInterceptor";

export const getGuestUser = async () => {
  try {
    const response = await axiosHandler.get("/api/guest/details");
    return response;
  } catch (error) {
    throw error;
  }
};
