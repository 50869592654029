import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
  IconButton,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AppContentView = ({ handleClose, appContentData }) => {
  const theme = useTheme();
  const hasBackgroundColor = appContentData?.background_color;
  const hasBgImage = appContentData?.background_image_url;

  return (
    <Card
      sx={{
        minWidth: 350,
        maxWidth: 350,
        height: 550,
        overflow: "auto",
        backgroundColor: hasBackgroundColor
          ? appContentData.background_color
          : theme.palette.background.alt,
        backgroundImage: hasBgImage
          ? `url(${appContentData.background_image_url})`
          : "none",
        backgroundSize: "cover",
        color: hasBackgroundColor || hasBgImage ? "white" : "inherit",
        textShadow:
          hasBackgroundColor || hasBgImage
            ? "1px 1px 2px rgba(0, 0, 0, 0.5)"
            : "none",
        position: "relative",
      }}
    >
      <CardHeader
        title={
          <Typography variant="h5" fontWeight="bold" textTransform="capitalize">
            {appContentData?.content_category}
          </Typography>
        }
        action={
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: theme.palette.primary[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      />

      <CardContent>
        <Typography
          variant="body2"
          mt={2}
          dangerouslySetInnerHTML={{ __html: appContentData?.html_content }}
        />
      </CardContent>

      <CardActions
        sx={{
          position: "absolute",
          right: 0,
          bottom: 0,
          margin: "0.5em",
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleClose}
        >
          Close
        </Button>
      </CardActions>
    </Card>
  );
};

export default AppContentView;
