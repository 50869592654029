import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { passwordChange } from "service/Auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "components/Header";
import ShowFullScreenBackdrop from "components/ShowFullScreenBackdrop";

const ChangePassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm New Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setOpen(true);
        const data = {
          current_password: values.currentPassword,
          new_password: values.newPassword,
        };
        const response = await passwordChange(data);

        if (response.status === 200) {
          formik.resetForm();
        }
        toast.success("Password change successful!");
        setOpen(false);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Password change failed. Please try again.");
        }
        setOpen(false);
      }
    },
  });

  const handleToggleShowPassword = (type) => {
    switch (type) {
      case "current":
        setShowCurrentPassword((prev) => !prev);
        break;
      case "new":
        setShowNewPassword((prev) => !prev);
        break;
      case "confirm":
        setShowConfirmPassword((prev) => !prev);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ToastContainer />
      <Box m="1.5rem 2.5rem">
        <Header title="CHANGE PASSWORD" subTitle="Change Password" />
        <Container maxWidth="sm" sx={{ marginTop: "40px", marginLeft: "40px" }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Current Password"
              color="secondary"
              type={showCurrentPassword ? "text" : "password"}
              {...formik.getFieldProps("currentPassword")}
              error={
                formik.touched.currentPassword &&
                Boolean(formik.errors.currentPassword)
              }
              helperText={
                formik.touched.currentPassword && formik.errors.currentPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleToggleShowPassword("current")}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: "80%" }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="New Password"
              color="secondary"
              type={showNewPassword ? "text" : "password"}
              {...formik.getFieldProps("newPassword")}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleToggleShowPassword("new")}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: "80%" }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Confirm New Password"
              color="secondary"
              type={showConfirmPassword ? "text" : "password"}
              {...formik.getFieldProps("confirmPassword")}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleToggleShowPassword("confirm")}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: "80%" }}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
            >
              Change Password
            </Button>
          </form>
        </Container>
      </Box>
      {open && <ShowFullScreenBackdrop open={open} />}
    </>
  );
};

export default ChangePassword;
