export const mockData = [
  {
    _id: "1",
    name: "John Doe",
    email: "john@example.com",
    mobile: "1234567890",
    country: "USA",
    role: 1,
  },
  {
    _id: "2",
    name: "Jane Smith",
    email: "jane@example.com",
    mobile: "9876543210",
    country: "Canada",
    role: 2,
  },
  {
    _id: "3",
    name: "Alice Johnson",
    email: "alice@example.com",
    mobile: "5551234567",
    country: "UK",
    role: 3,
  },
  {
    _id: "4",
    name: "Bob Brown",
    email: "bob@example.com",
    mobile: "3335557777",
    country: "Australia",
    role: 2,
  },
  {
    _id: "5",
    name: "Ella Davis",
    email: "ella@example.com",
    mobile: "1112223333",
    country: "Germany",
    role: 3,
  },
  {
    _id: "6",
    name: "Sam Wilson",
    email: "sam@example.com",
    mobile: "4448889999",
    country: "France",
    role: 3,
  },
  {
    _id: "7",
    name: "Grace Lee",
    email: "grace@example.com",
    mobile: "7778889999",
    country: "Japan",
    role: 3,
  },
  {
    _id: "8",
    name: "David Clark",
    email: "david@example.com",
    mobile: "6667778888",
    country: "Spain",
    role: 3,
  },
  {
    _id: "9",
    name: "Sophia Martinez",
    email: "sophia@example.com",
    mobile: "9998887777",
    country: "Italy",
    role: 3,
  },
  {
    _id: "10",
    name: "Oliver Rodriguez",
    email: "oliver@example.com",
    mobile: "2223334444",
    country: "Brazil",
    role: 3,
  },
  {
    _id: "11",
    name: "Lily Hernandez",
    email: "lily@example.com",
    mobile: "3339991111",
    country: "India",
    role: 3,
  },
  {
    _id: "12",
    name: "William Moore",
    email: "william@example.com",
    mobile: "1114447777",
    country: "China",
    role: 3,
  },
  {
    _id: "13",
    name: "Chloe Perez",
    email: "chloe@example.com",
    mobile: "8884442222",
    country: "Russia",
    role: 3,
  },
  {
    _id: "14",
    name: "Daniel Sanchez",
    email: "daniel@example.com",
    mobile: "5556667777",
    country: "Mexico",
    role: 3,
  },
  {
    _id: "15",
    name: "Ava Nguyen",
    email: "ava@example.com",
    mobile: "6669991111",
    country: "South Africa",
    role: 2,
  },
];

export const modules = {
  toolbar: {
    container: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  },
};

export const formats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "align",
  "list",
  "bullet",
  "indent",
  "blockquote",
  "code-block",
  "link",
  "image",
  "video",
];

export const initialData = {
  roleId: 2,
  permissions: [
    { title: "Mobile App Users", access: "write" },
    { title: "Admin Users", access: "write" },
    { title: "Part", access: "write" },
    { title: "Course", access: "write" },
    { title: "Meditation", access: "write" },
    { title: "Podcast", access: "write" },
    { title: "Wisdom", access: "write" },
    { title: "Quiz", access: "write" },
    { title: "Role", access: "write" },
    { title: "Role Access", access: "write" },
    { title: "Contact Us", access: "write" },
    { title: "FAQ", access: "write" },
    { title: "Privacy Policy", access: "write" },
    { title: "Terms Conditions", access: "write" },
  ],
};
