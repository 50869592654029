import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import Header from "components/Header";
// import logoImage from "../../assets/Group 27755.png";
import { signIn } from "service/Auth";
// import CryptoJS from "crypto-js";

const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

// const encryptPermissions = (permissions) => {
//   // Convert permissions object to string
//   const permissionsString = JSON.stringify(permissions);
//   // Encrypt permissions string
//   const encryptedPermissions = CryptoJS.AES.encrypt(
//     permissionsString,
//     "secret_key"
//   ).toString();
//   return encryptedPermissions;
// };

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await signIn(values);

        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("roleId", JSON.stringify(response.data.roleId)); // Assuming `response` contains roleId

          // // Encrypt and store permissions securely
          // const encryptedPermissions = encryptPermissions(
          //   response.data.permissions
          // );
          // localStorage.setItem("permissions", encryptedPermissions);
          navigate("/");
          // Reload the page
          window.location.reload();
          toast.success("Login successful!");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Login failed. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <Grid container className="css-mhc70k-MuiGrid-root">
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "linear-gradient(to right, #F80B7A, #841EA8)",
              height: "100%",
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* <img
              src={logoImage}
              alt="logo"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "50%",
                maxHeight: "50%",
              }}
            /> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ marginTop: 16 }} display="grid" gap={3}>
                <Header title="LOG-IN" subTitle="Please login to continue." />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  label="Email"
                  name="email"
                  color="secondary"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  color="secondary"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box display="flex" justifyContent="center" mt={4}>
                <Button
                  type="submit"
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disabled={loading || formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <>
                      <CircularProgress size={22} color="inherit" />
                      <span style={{ marginLeft: "8px" }}>Logging in...</span>
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginForm;
