import axiosHandler from "interceptor/axiosInterceptor";

export const getAppPost = async () => {
  try {
    const response = await axiosHandler.get("/app-post/details");
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAppPostById = async (postId) => {
  try {
    const response = await axiosHandler.get(`/app-post/post/${postId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addAppPost = async (data) => {
  try {
    const response = await axiosHandler.post("/app-post/add", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAppPost = async (data, postId) => {
  try {
    const response = await axiosHandler.put(
      `/app-post/edit-post/${postId}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAppPost = async (postId, post_status) => {
  try {
    const response = await axiosHandler.put(
      `/app-post/delete/${postId}`,
      post_status
    );
    return response;
  } catch (error) {
    throw error;
  }
};
