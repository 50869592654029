import { Dialog } from "@mui/material";
import PostContentView from "./PostContentView";

const PostContentModal = ({ open, handleClose, postContentData }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <PostContentView
        handleClose={handleClose}
        postContentData={postContentData}
      />
    </Dialog>
  );
};

export default PostContentModal;
